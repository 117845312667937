import colors from "tailwindcss/colors";

const defaultThemeColors = {
  transparent: "transparent",
  black: colors.black,
  white: colors.white,
  gray: colors.neutral,
  primary: {
    DEFAULT: "#7C1CFF",
    25: "#f2e9ff",
    50: "#E6D4FF",
    100: "#DABFFF",
    200: "#C396FF",
    300: "#AB6EFF",
    400: "#9445FF",
    500: "#7C1CFF",
    600: "#6000E3",
    700: "#4800AB",
    800: "#310073",
    900: "#19003B",
  },
  secondary: {
    DEFAULT: "#20CEE8",
    50: "#C6F3F9",
    100: "#B4EFF7",
    200: "#8FE6F3",
    300: "#6ADEF0",
    400: "#45D6EC",
    500: "#20CEE8",
    600: "#13A6BC",
    700: "#0E7A8A",
    800: "#094D57",
    900: "#042024",
  },
  cream: {
    DEFAULT: "#F9F5F3",
    50: "#FFFFFF",
    100: "#F9F5F3",
    200: "#E6D6CE",
    300: "#D4B7A8",
    400: "#C19883",
    500: "#AE785D",
    600: "#8D5E46",
    700: "#684534",
    800: "#422C21",
    900: "#1D130E",
    950: "#0A0705",
  },
  warning: {
    DEFAULT: "#f97316",
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
    950: "#431407",
  },
  danger: {
    DEFAULT: "#CC3232",
    50: "#FAEAEA",
    100: "#F5D5D5",
    200: "#EBACAC",
    300: "#E18383",
    400: "#D75A5A",
    500: "#CC3232",
    600: "#A32828",
    700: "#7A1E1E",
    800: "#511414",
    900: "#280A0A",
  },
  success: {
    DEFAULT: "#3EDE9C",
    50: "#DBF9EC",
    100: "#C9F6E3",
    200: "#A7F0D2",
    300: "#84EAC0",
    400: "#61E4AE",
    500: "#3EDE9C",
    600: "#21C380",
    700: "#199361",
    800: "#116341",
    900: "#093321",
  },
  navy: {
    DEFAULT: "#001B38",
    50: "#94C7FF",
    100: "#7FBDFF",
    200: "#57A8FF",
    300: "#2E93FF",
    400: "#057EFF",
    500: "#006ADB",
    600: "#0056B2",
    700: "#00428A",
    800: "#002F61",
    900: "#001B38",
  },
};

export {defaultThemeColors};
